import { navigate } from "gatsby"
import * as React from "react"
import Button from "./button"
import Question from "./question"

const questions = [
  {
    id: 1,
    question: "What type of service are you looking for?",
    inputType: "select",
    options: [
      "Spray Foam Insulation",
      "Polyurea Coating",
      "Blown-In Insulation",
    ],
    info: null,
    dependency: null,
  },
  {
    id: 2,
    question: "What is the type of structure?",
    inputType: "text",
    options: null,
    info: null,
    dependency: { questionId: 1, dependentValue: "Spray Foam Insulation" },
  },
  {
    id: 3,
    question: "What structures need to be sprayed?",
    inputType: "multiselect",
    options: ["Walls", "Crawl Space / Basement"],
    info: null,
    dependency: { questionId: 1, dependentValue: "Spray Foam Insulation" },
  },
  {
    id: 4,
    question: "Please describe the application:",
    inputType: "text",
    options: null,
    info: null,
    dependency: { questionId: 1, dependentValue: "Polyurea Coating" },
  },
  {
    id: 5,
    question: "What are the dimensions of your project?",
    inputType: "text",
    options: null,
    info: null,
    dependency: null,
  },
  {
    id: 6,
    question: "Do you want to use open or closed cell spray foam?",
    inputType: "select",
    options: ["Open Cell Foam", "Closed Cell Foam"],
    info: "Closed cell foam has a higher R-value of around 7 per inch and creates an air and vapor barrier, while open cell foam has an R value of around 3.5 per inch and creates an air barrier.",
    dependency: { questionId: 1, dependentValue: "Spray Foam Insulation" },
  },
  {
    id: 7,
    question: "What insulation thickness is required?",
    inputType: "select",
    options: [
      '2" (R7)',
      '4" (R14)',
      '6" (R21)',
      '8" (R28)',
      '10" (R35)',
      '12" (R42)',
      '14" (R49)',
    ],
    info: "Open cell foam has an R-value of about 3.5 per inch",
    dependency: { questionId: 6, dependentValue: "Open Cell Foam" },
  },
  {
    id: 8,
    question: "What insulation thickness is required?",
    inputType: "select",
    options: [
      '1" (R7)',
      '2" (R14)',
      '3" (R21)',
      '4" (R28)',
      '5" (R35)',
      '6" (R42)',
      '7" (R49)',
    ],
    info: "Closed cell foam has an R-value of about 7 per inch",
    dependency: { questionId: 6, dependentValue: "Closed Cell Foam" },
  },
  {
    id: 9,
    question:
      "Do you have any questions or anything else you would like to tell us about your project?",
    inputType: "text",
    options: null,
    info: null,
    dependency: null,
  },
]

const ProjectQuestionnaireForm = ({
  id,
  className,
  onQuestionChange,
  name,
  email,
  phone,
}) => {
  const [filteredQuestions, setFilteredQuestions] = React.useState([])
  const [questionIndex, setQuestionIndex] = React.useState(0)
  const [answers, setAnswers] = React.useState({})
  const [formBody, setFormBody] = React.useState("")

  React.useEffect(() => {
    setFilteredQuestions(
      questions.filter(question => {
        return (
          !question.dependency ||
          Object.keys(answers).find(
            answerKeyQuestion =>
              answerKeyQuestion === // Match response to question's dependent question if it has one
                questions.find(
                  dependentQuestion =>
                    dependentQuestion.id === question.dependency.questionId
                ).question &&
              answers[answerKeyQuestion] === question.dependency.dependentValue // Also match the response to this question's dependent question to ensure the dependent value is selected. If so, this is valid and gets added to the list.
          )
        )
      })
    )

    const formBody = {
      "form-name": "project-questionnaire",
      subject: `New Canopy project questionnaire from ${name}`,
      Name: name,
      Email: email,
      Phone: phone,
      ...Object.assign(
        {},
        ...Object.keys(answers).map(question => {
          return {
            [question]: Array.isArray(answers[question])
              ? answers[question]?.map(selection => selection.value)?.join(",")
              : answers[question],
          }
        })
      ),
    }
    // .filter(input => !!input) // Filter out falsy values (nulls, etc)
    // .join("&")

    console.debug("Form body: ", formBody)

    setFormBody(formBody)
  }, [name, email, phone, answers])

  const encode = data => {
    const encoded = Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")

    console.debug("Encoded Body: ", encoded)
    return encoded
  }

  const handleNextQuestion = () => {
    setQuestionIndex(questionIndex + 1)
    onQuestionChange(questionIndex + 1)
  }

  const handlePrevQuestion = () => {
    setQuestionIndex(questionIndex - 1)
    onQuestionChange(questionIndex - 1)
  }

  const handleAnswerChanged = answer => {
    console.debug("Answer changed: ", answer)

    const newAnswers = {
      ...answers,
      [filteredQuestions[questionIndex].question]: answer,
    }

    console.debug("New Answers: ", newAnswers)
    setAnswers(newAnswers)
  }

  const handleSubmit = event => {
    event.preventDefault()

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode(formBody),
    })
      .then(() => navigate("/project-questionnaire-submitted/"))
      .catch(error => alert(error))
  }

  // const questionNumber = questionIndex + 1
  const currentQuestion = filteredQuestions[questionIndex]
  const currentAnswer = answers[filteredQuestions[questionIndex]?.question]

  return (
    <section id={id} className={`${className}`}>
      {/* <div className="text-center py-4">
        <H3>
          Question {questionNumber} of {filteredQuestions.length}
        </H3>
      </div> */}

      {!!currentQuestion && (
        <Question
          question={currentQuestion.question}
          inputType={currentQuestion.inputType}
          options={currentQuestion.options}
          info={currentQuestion.info}
          value={currentAnswer}
          onChange={handleAnswerChanged}
        />
      )}

      <div className="flex justify-center pt-12 pb-4 gap-4">
        {questionIndex !== 0 && (
          <Button className="w-36" onClick={handlePrevQuestion}>
            Prev
          </Button>
        )}
        {questionIndex < filteredQuestions.length - 1 && (
          <Button className="w-36" onClick={handleNextQuestion}>
            Next
          </Button>
        )}
        {questionIndex === filteredQuestions.length - 1 && (
          <Button className="w-36" onClick={handleSubmit}>
            Submit
          </Button>
        )}
      </div>

      {/* Hidden HTML form needed for Netlify */}
      <form
        hidden
        name="project-questionnaire"
        action="/project-questionnaire-submitted"
        method="post"
        data-netlify="true"
        netlify-honeypot="honey-pot"
      >
        <input type="hidden" name="honey-pot" />
        <input type="hidden" name="form-name" value="project-questionnaire" />
        <input
          type="hidden"
          name="subject"
          value={`New Canopy project questionnaire from ${name}`}
        />
        {questions.map(question => (
          <>
            <label htmlFor={question.question}>{question.question}</label>
            <input
              readOnly={true}
              id={question.question}
              name={question.question}
            />
          </>
        ))}
      </form>
    </section>
  )
}

export default ProjectQuestionnaireForm
