import * as React from "react"
import H1 from "../components/h1"
import Layout from "../components/layout"
import ProjectQuestionnaireForm from "../components/project-questionnaire-form"
import Seo from "../components/seo"

export default function ThankYouPage({ location }) {
  const [hideThankYou, setHideThankYou] = React.useState(false)
  const [name, setName] = React.useState()
  const [email, setEmail] = React.useState()
  const [phone, setPhone] = React.useState()

  React.useEffect(() => {
    console.debug("Location: ", location)

    const params = location?.search?.split("&") || []

    console.debug("Params: ", params)

    const paramName = params.find(param => param.includes("name="))
    const paramEmail = params.find(param => param.includes("email="))
    const paramPhone = params.find(param => param.includes("phone="))

    if (paramName) {
      setName(decodeURIComponent(paramName)?.split("=")[1])
    }

    if (paramEmail) {
      setEmail(decodeURIComponent(paramEmail)?.split("=")[1])
    }

    if (paramPhone) {
      setPhone(decodeURIComponent(paramPhone)?.split("=")[1])
    }
  }, [location])

  return (
    <Layout>
      <Seo title="Thank You" />
      <section className="py-8 px-8 lg:px-16">
        {!hideThankYou && (
          <>
            <H1 className="text-center">Thank You!</H1>
            <p className="py-4 italic text-lg text-center font-semibold">
              Your request will be reviewed and we will send you a response via
              email.
            </p>

            <p className="py-4 italic text-lg text-center font-semibold">
              To help us generate a more accurate estimate for your project,
              please fill out the following project questionairre. Thank you!
            </p>
          </>
        )}

        <ProjectQuestionnaireForm
          id="project-questionnaire-form"
          onQuestionChange={() => setHideThankYou(true)}
          name={name}
          email={email}
          phone={phone}
        />
      </section>
    </Layout>
  )
}
