import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Tooltips from "@material-tailwind/react/Tooltips"
import TooltipsContent from "@material-tailwind/react/TooltipsContent"
import * as React from "react"
import Emphasis from "./emphasis"

const Question = ({ question, inputType, options, info, value, onChange }) => {
  const infoTooltipRef = React.useRef()

  const [selectedOptions, setSelectedOptions] = React.useState([])
  const [textValue, setTextValue] = React.useState("")
  const [isOtherToggled, setIsOtherToggled] = React.useState(false)

  React.useEffect(() => {
    console.debug("Question props updated: ", {
      question,
      inputType,
      options,
      value,
    })

    switch (inputType) {
      case "text":
        // Assume value to be a string and setup text value
        setSelectedOptions([])
        setTextValue(value || "")
        setIsOtherToggled(false)
        break

      case "select":
        // Assume value to be a string but set as selected option
        setSelectedOptions(value ? [{ value, isOther: false }] : [])
        setTextValue("")
        setIsOtherToggled(false)
        break

      case "multiselect":
        // Assume value to be an array of { value, isOther }
        setSelectedOptions(value ? [...value] : [])
        setTextValue(value?.find(option => option.isOther)?.value || "")
        setIsOtherToggled(!!value?.find(option => option.isOther))
        break
      default:
        break
    }
  }, [value, inputType, options, question])

  const handleTextValueChanged = () => {
    console.debug("Text value changed: ", textValue)

    // If multiselect, need to pass text value back as an array option
    if (inputType === "multiselect") {
      onChange([
        ...selectedOptions.filter(opt => !opt.isOther),
        {
          value: textValue,
          isOther: true,
        },
      ])
    }
    // Otherwise it is the value
    else {
      onChange(textValue)
    }
  }

  const handleOptionToggled = option => {
    console.debug("Option toggled: ", option)

    // If multiselect, object structure will be different
    if (inputType === "multiselect") {
      if (selectedOptions.map(opt => opt.value).includes(option)) {
        onChange([...selectedOptions.filter(opt => opt.value !== option)])
      } else {
        onChange([
          ...selectedOptions,
          {
            value: option,
            isOther: false,
          },
        ])
      }
    } else {
      if (selectedOptions.includes(option)) {
        onChange(null)
      } else {
        onChange(option)
      }
    }
  }

  const handleToggleOther = () => {
    console.debug("Other option toggled")
    setIsOtherToggled(true)
  }

  const selectedButtonClass =
    "inline-block px-4 py-2 rounded-lg shadow-md cursor-pointer font-semibold text-xl border-2 border-primary bg-primary hover:bg-primary hover:border-primary focus:border-primary active:border-primary"
  const unselectedButtonClass =
    "inline-block px-4 py-2 rounded-lg shadow-md cursor-pointer font-semibold text-xl border-2 border-tertiary hover:bg-primary hover:border-primary focus:border-primary active:border-primary"

  return (
    <div className="text-center py-4">
      <Emphasis>{question}</Emphasis>
      {!!info && (
        <>
          <span ref={infoTooltipRef} className="ml-2">
            <FontAwesomeIcon icon={faInfoCircle} className="text-2xl" />
          </span>
          <Tooltips placement="top" ref={infoTooltipRef}>
            <TooltipsContent>{info}</TooltipsContent>
          </Tooltips>
        </>
      )}

      {inputType === "multiselect" && (
        <div className="text-center font-semibold uppercase pt-8">
          SELECT ALL THAT APPLY
        </div>
      )}

      {/* Button container */}
      {inputType !== "text" && (
        <div className="w-full flex justify-center p-4 gap-4">
          {/* Option buttons */}
          {options.map(option => {
            const isSelected = selectedOptions
              .map(opt => opt.value)
              .includes(option)
            return (
              <button
                key={option}
                className={
                  isSelected ? selectedButtonClass : unselectedButtonClass
                }
                onClick={() => handleOptionToggled(option)}
              >
                {option}
              </button>
            )
          })}

          {/* Other button */}
          {inputType === "multiselect" && (
            <button
              className={
                isOtherToggled ? selectedButtonClass : unselectedButtonClass
              }
              onClick={() => handleToggleOther()}
            >
              Other
            </button>
          )}
        </div>
      )}

      {(inputType === "text" ||
        (inputType === "multiselect" && isOtherToggled)) && (
        <div className="py-4 w-2/3 mx-auto">
          <textarea
            placeholder="Type here..."
            id={`${question}-text`}
            name={`${question}-text`}
            rows="4"
            value={textValue}
            onChange={e => setTextValue(e.target.value)}
            onBlur={handleTextValueChanged}
            className="form-textarea mt-1 text-lg w-full px-4 py-3 rounded-lg border-gray-100 focus:border-primary-highlighted focus:ring-1 focus:ring-primary-highlighted
shadow-md"
          ></textarea>
        </div>
      )}
    </div>
  )
}

export default Question
